import React from "react"
import useInlineStyle from "../UseInlineStyle"

export default function ImageBuilder(props) {
  const { jsonData, uiObjectId } = props.object
  const {
    image_href,
    width,
    height,
    className,
    inlineStyle,
    alt,
    pageVariable,
    websiteVariable,
  } = JSON.parse(jsonData)  
  let finalSrc = image_href
  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalSrc = websiteVariables[uiObjectId]
      ? websiteVariables[uiObjectId].content
      : image_href
  } else if (pageVariable) {
    let value

    if (
      props.page &&
      props.page.jsonData &&
      jsonData[props.object.uiObjectId]
    ) {
      value = jsonData[props.object.uiObjectId].content
    } else if (
      props.pageData &&
      props.pageData.jsonData &&
      props.pageData.jsonData[props.object.uiObjectId]
    ) {
      value = props.pageData.jsonData[props.object.uiObjectId].content
    } else {
      value = image_href
    }
    finalSrc = value
  } else {
    finalSrc = image_href
  }

  const {styleObject} = useInlineStyle(inlineStyle)
  

  return (
    <img
      src={finalSrc}
      width={width}
      alt={alt ? alt : props?.object?.objectName}
      height ={height}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      style={styleObject}
      className={className}
      loading="lazy"
    />
  )
}
